<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Students
          <el-button type="primary" @click="copySelectedStudentsToUsers">
            Copy Selected Students To Users
          </el-button>
          <el-button type="primary" @click="updateStudentsGradePerYear">
            Update Students' Grade
          </el-button>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.username"
          placeholder="Student Name"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-input
          v-model="query.mobileNo"
          placeholder="Mobile No."
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-input
          v-model="query.email"
          placeholder="Email"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-select
          v-model="query.grade"
          placeholder="Grade"
          :clearable="true"
          class="mr10"
        >
          <el-option
            v-for="item in options.gradeList"
            :key="item.value"
            :label="item.description"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Add a Student
        </el-button>
      </div>
      <el-table
        :data="tableData"
        class="table"
        stripe
        header-cell-class-name="table-header"
        :default-sort="{ prop: 'StudentId', order: 'descending' }"
        @sort-change="changeSort"
        @selection-change="handleStudentSelectionChange"
      >
        <el-table-column type="selection" width="35" />
        <el-table-column
          prop="studentId"
          label="Student No."
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="StudentId"
          width="108"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="Full name"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Username"
          width="140"
        ></el-table-column>
        <el-table-column label="Points" width="80">
          <template #default="scope">
            {{
              scope.row.points +
                scope.row.pendingPoints +
                scope.row.creditPoints
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          label="Email"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="mobileNo"
          label="Mobile No."
          width="160"
        ></el-table-column>
        <el-table-column
          prop="school"
          label="School"
          width="120"
        ></el-table-column>
        <el-table-column prop="grade" label="Grade" width="60">
        </el-table-column>
        <el-table-column
          prop="enrolledTime"
          sortable="true"
          :formatter="$tableCellFormatter.formatDate"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="EnrolledTime"
          label="Enroll Time"
          width="108"
        ></el-table-column>
        <el-table-column label="Operation" align="center">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
            <el-button
              v-if="false"
              type="text"
              @click="copyStudentToUser(scope.$index, scope.row)"
            >
              <el-icon>
                <user />
              </el-icon>
              Create User
            </el-button>
            <el-button
              type="text"
              @click="openIssuePointsDialog(scope.$index, scope.row)"
            >
              <el-icon>
                <coin />
              </el-icon>
              Issue Points
            </el-button>
            <el-button type="text" @click="gotoUser(scope.$index, scope.row)">
              <el-icon>
                <user-filled />
              </el-icon>
              To User
            </el-button>
            <el-button
              type="text"
              @click="printReceipt(scope.$index, scope.row)"
            >
              <el-icon>
                <printer />
              </el-icon>
              Receipt
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      v-model="editVisible"
      v-if="editVisible"
      :lock-scroll="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-form ref="mainForm" :model="form" :rules="rules" label-width="200px">
        <div class="dialogHeaderTitle">Student Information</div>
        <el-form-item v-if="newMode == false" label="Student No.">
          <el-input readonly v-model="form.studentId"></el-input>
        </el-form-item>
        <el-form-item prop="firstName" label="First Name">
          <el-input v-model="form.firstName"></el-input>
        </el-form-item>
        <el-form-item prop="lastName" label="Last Name">
          <el-input v-model="form.lastName"></el-input>
        </el-form-item>
        <el-form-item prop="email" label="Email">
          <el-input v-model="form.email" @change="syncEmailValue"></el-input>
        </el-form-item>
        <el-form-item prop="mobileNo" label="Mobile No.">
          <el-input v-model="form.mobileNo"></el-input>
        </el-form-item>
        <el-form-item prop="dateOfBirth" label="Date of birth">
          <el-date-picker
            type="date"
            placeholder="Date of birth"
            v-model="form.dateOfBirth"
            class="handle-input"
            style="margin-right: 10px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="gender" label="Gender">
          <el-select v-model="form.gender" placeholder="Please select">
            <el-option label="Male" value="Male"></el-option>
            <el-option label="Female" value="Female"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="school" label="Current School">
          <el-input v-model="form.school"></el-input>
        </el-form-item>
        <el-form-item prop="enrolledTime" label="Enrol date">
          <el-date-picker
            type="date"
            placeholder="Enrol date"
            v-model="form.enrolledTime"
            class="handle-input"
            style="margin-right: 10px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          prop="grade"
          :label="newMode ? 'Enrolled grade' : 'Current Grade'"
        >
          <el-select v-model="form.grade" placeholder="Please select...">
            <el-option
              v-for="item in options.gradeList"
              :key="item.value"
              :label="item.description"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Has your child studied at JE MATHS before?">
          <el-switch v-model="form.haveStudied"></el-switch>
        </el-form-item>
        <el-form-item label="Note">
          <el-input type="textarea" rows="5" v-model="form.note"></el-input>
        </el-form-item>
        <el-form-item prop="status" label="Status">
          <el-select v-model="form.status" placeholder="Please select...">
            <el-option label="Normal" value="Normal"></el-option>
            <el-option label="Locked" value="Locked"></el-option>
          </el-select>
        </el-form-item>

        <div class="dialogHeaderTitle">Parent/Guardian</div>
        <div v-for="(item, index) in form.parents" :key="index">
          <el-form-item
            :prop="`parents.${index}.firstName`"
            :rules="rules.parentFirstName"
            label="First Name"
          >
            <el-input v-model="item.firstName"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.lastName`"
            :rules="rules.parentLastName"
            label="Last Name"
          >
            <el-input v-model="item.lastName"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.email`"
            :rules="rules.parentEmail"
            label="Email"
          >
            <el-input v-model="item.email"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.relationship`"
            :rules="rules.parentRelationship"
            label="Relationship"
          >
            <el-select
              v-model="item.relationship"
              placeholder="Please select..."
            >
              <el-option label="Father" value="Father"></el-option>
              <el-option label="Mother" value="Mother"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.address`"
            :rules="rules.parentAddress"
            label="Address"
          >
            <el-input v-model="item.address"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.suburb`"
            :rules="rules.parentSurburb"
            label="Suburb"
          >
            <el-input v-model="item.suburb"></el-input>
          </el-form-item>
          <el-form-item
            :prop="`parents.${index}.postcode`"
            :rules="rules.parentPostcode"
            label="Postcode"
          >
            <el-input v-model="item.postcode"></el-input>
          </el-form-item>
          <el-form-item label="How did you hear about us?">
            <el-select
              v-model="item.whereToKnow"
              @change="selectChanged(index, $event)"
              placeholder="Please select..."
              style="float:left;"
            >
              <el-option
                v-for="optItem in options.whereToKnowOptions"
                :key="optItem.label"
                :label="optItem.label"
                :value="optItem.label"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.whereToKnowAddition"
              :type="whereToKnowInputType"
              style="float:left;margin-left:20px;width:30%"
            ></el-input>
          </el-form-item>
        </div>

        <!-- <div class="dialogHeaderTitle">Courses of interest</div>
        <el-form-item label="Junior Courses">
          <el-checkbox-group v-model="form.coursesOfInterestArray">
            <el-checkbox label="Year 6(Year 7 Maths Course)"></el-checkbox>
            <el-checkbox label="Year 7(Year 8 Maths Course)"></el-checkbox>
            <el-checkbox label="Year 8(Year 9 Maths Course)"></el-checkbox>
            <el-checkbox label="Year 9(Year 10 Maths Course)"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item prop="coursesOfInterestArray" label="HSC Courses">
          <el-checkbox-group v-model="form.coursesOfInterestArray">
            <el-checkbox label="Year 10 - Advanced Maths course"></el-checkbox>
            <el-checkbox label="Year 11 - Advanced Maths course"></el-checkbox>
            <el-checkbox
              label="Year 11 - Extension 1 Maths course"
            ></el-checkbox>
            <el-checkbox
              label="Year 11 - Extension 2 Maths course"
            ></el-checkbox>
            <el-checkbox
              label="Year 12 - Extension 1 Maths course"
            ></el-checkbox>
            <el-checkbox
              label="Year 12 - Extension 2 Maths course"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveEdit">Save</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :title="addPointsDialogTitle"
      v-model="showAddPointsDialog"
      width="60%"
    >
      <add-points
        :initData="{
          student: currentStudentForIssuePoints,
          callback: issuedPointsCallback,
        }"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getData, deleteData, putData, postData } from "../../service/api";
import AddPoints from "./AddPoints.vue";

export default {
  name: "studentList",
  components: { AddPoints },
  data() {
    return {
      controllerUrl: "/student",
      options: {
        gradeList: [],
        whereToKnowOptions: [
          {
            label: "Google Search Ads",
          },
          {
            label: "YouTube Ads",
          },
          {
            label: "Recommend from friends",
          },
          {
            label: "From Mr Ye.",
          },
          {
            label: "Other",
          },
        ],
      },
      query: {
        username: null,
        mobileNo: null,
        email: null,
        startEnrolledTime: null,
        endEnrolledTime: null,
        grade: null,
        orderBy: null,
        orderDirection: null,
        pageIndex: 1,
        pageSize: 50,
      },
      copyToUserQuery: {
        all: false,
        studentIdList: [],
      },
      tableData: [],
      showAddPointsDialog: false,
      addPointsDialogTitle: "",
      currentStudentForIssuePoints: null,
      dialogTitle: "",
      editVisible: false,
      newMode: false,
      whereToKnowInputType: "hidden",
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input student First Name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input student Last Name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            pattern: /^[a-zA-Z0-9.!#$%&'^_`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: "Please input correct email",
            trigger: "blur",
          },
        ],
        mobileNo: [
          {
            required: true,
            message: "Please input student Mobile No.",
            trigger: "blur",
          },
        ],
        // dateOfBirth: [
        //   {
        //     type: "date",
        //     required: true,
        //     message: "Please select student date of birth",
        //     trigger: "change",
        //   },
        // ],
        // gender: [
        //   {
        //     required: true,
        //     message: "Please select student gender",
        //     trigger: "change",
        //   },
        // ],
        // school: [
        //   {
        //     required: true,
        //     message: "Please input student current school",
        //     trigger: "blur",
        //   },
        // ],
        grade: [
          {
            required: true,
            message: "Please select student current grade",
            trigger: "change",
          },
        ],
        // enrolledTime: [
        //   {
        //     type: "date",
        //     required: true,
        //     message: "Please select student enrol date",
        //     trigger: "change",
        //   },
        // ],
        // parentFirstName: [
        //   {
        //     required: true,
        //     message: "Please input parent First Name",
        //     trigger: "blur",
        //   },
        // ],
        // parentLastName: [
        //   {
        //     required: true,
        //     message: "Please input parent Last Name",
        //     trigger: "blur",
        //   },
        // ],
        parentEmail: [
          {
            pattern: /^[a-zA-Z0-9.!#$%&'^_`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: "Please input correct Email",
            trigger: "blur",
          },
        ],
        // parentMobileNo: [
        //   {
        //     required: true,
        //     message: "Please input parent Mobile No.",
        //     trigger: "blur",
        //   },
        // ],
        // parentRelationship: [
        //   {
        //     required: true,
        //     message: "Please input select Relationship.",
        //     trigger: "change",
        //   },
        // ],
        // parentAddress: [
        //   {
        //     required: true,
        //     message: "Please input parent Address.",
        //     trigger: "blur",
        //   },
        // ],
        // parentSurburb: [
        //   {
        //     required: true,
        //     message: "Please input parent address's Surbub.",
        //     trigger: "blur",
        //   },
        // ],
        // parentPostcode: [
        //   {
        //     required: true,
        //     message: "Please input parent address's Postcode.",
        //     trigger: "blur",
        //   },
        // ],
        // coursesOfInterest: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "Please select courses of your interest.",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;

    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item？", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.studentId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(
                "Delete failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    handleCreate() {
      this.form = {
        status: "Normal",
        parents: [{ relationship: "Mother" }],
        enrolledTime: new Date(),
        coursesOfInterestArray: [],
      };
      this.dialogTitle = "Enrolment Form (NEW Mode)";
      this.whereToKnowInputType = "hidden";
      this.editVisible = true;
      this.newMode = true;
    },
    handleEdit(index, row) {
      this.idx = index;
      this.dialogTitle = "Enrolment Form (EDIT Mode)";
      this.whereToKnowInputType = "hidden";
      this.newMode = false;
      getData(this.controllerUrl + "/" + row.studentId, null)
        .then((res) => {
          if (res.result && res.code === "200") {
            console.log("handleEdit.getData", res.result);
            this.form = res.result;

            this.form["coursesOfInterestArray"] = this.form.coursesOfInterest
              ? this.form.coursesOfInterest.split(",")
              : [];
            if (this.form.parents && this.form.parents.length > 0) {
              for (let i = 0; i < this.form.parents.length; i++) {
                this.selectChanged(i, this.form.parents[i].whereToKnow);
              }
            }

            console.log(
              "coursesOfInterestArray",
              this.form["coursesOfInterestArray"]
            );
            this.editVisible = true;
          } else {
            this.$message.error(
              "Fetch student data failed, error message: " + res.message
            );
          }
        })
        .catch(() => {});
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid, failedRules) => {
        if (valid) {
          let coursesOfInterestArray = this.form["coursesOfInterestArray"];
          if (coursesOfInterestArray && coursesOfInterestArray.length > 0) {
            this.form.coursesOfInterest = "".concat(coursesOfInterestArray);
            console.log(
              "this.form.coursesOfInterest",
              this.form.coursesOfInterest
            );
          } else {
            this.form.coursesOfInterest = "";
          }
          if (
            this.form.studentId &&
            this.form.studentId.length === "00000000".length
          ) {
            putData(this.controllerUrl, this.form.studentId, this.form).then(
              (res) => {
                if (res.result && res.code === "200") {
                  this.editVisible = false;
                  this.$message.success("Update successfully");
                } else {
                  this.$message.error(
                    "Update failed, error message: " + res.message
                  );
                }
              }
            );
          } else {
            postData(this.controllerUrl, this.form).then((res) => {
              console.log("res", res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;
                this.loadData();
              } else {
                this.$message.error(
                  "Create failed, error message: " + res.message
                );
              }
            });
          }
        } else {
          let errorMessage = "";
          for (let rule in failedRules) {
            if (errorMessage) errorMessage = `${errorMessage}; ${rule}: `;
            else errorMessage = `${rule}: `;
            for (let ei = 0; ei < failedRules[rule].length; ei++) {
              errorMessage += failedRules[rule][ei].message + " ";
            }
          }

          this.$message.error(
            "Validation failed, please correct! " + errorMessage
          );
          return false;
        }
      });
    },
    selectChanged(index, item) {
      if (item === "Recommend from friends" || item === "Other") {
        this.whereToKnowInputType = "input";
      } else {
        this.whereToKnowInputType = "hidden";
        this.form.parents[index].whereToKnowAddition = "";
      }
      console.log(
        "selectChanged",
        this.form.parents[index].whereToKnowAddition,
        index,
        item
      );
    },
    syncEmailValue(val) {
      this.form.email = val;
      if (this.form.parents && this.form.parents.length > 0) {
        for (let i = 0; i < this.form.parents.length; i++) {
          this.form.parents[i].email = val;
        }
      }
    },
    getCurrentGrade(index, row) {
      if (!row.grade) return "";
      let createdTime = new Date(row.createdTime);
      let diff = new Date().getFullYear() - createdTime.getFullYear();
      let grade = row.grade.substring(4, row.grade.length);
      if (grade > 12) grade = 12;
      let gradeVal = `Year${parseInt(grade) + diff}`;
      return gradeVal;
    },
    printReceipt(index, row) {
      let route = this.$router.resolve({ path: "/buildReceiptTemplate" });
      window.open(`${route.href}?studentId=${row.studentId}`, "_blank");
    },
    gotoUser(index, row) {
      let route = this.$router.resolve({ path: "/userList" });
      window.open(`${route.href}?username=${row.studentId}`, "_blank");
    },
    updateStudentsGradePerYear() {
      getData(`${this.controllerUrl}/updateStudentGradePerYear`)
        .then((res) => {
          if (res.code === "200") {
            this.$message.success("Updated successfully");
          } else {
            this.$message.warning(`Updated failed, error: ${res.message}.`);
          }
        })
        .catch(() => {
          this.$message.error("Unexpected error.");
        });
    },
    checkEmail(rule, value, callback) {
      let _this = this;
      setTimeout(() => {
        if (value) {
          getData(`${_this.controllerUrl}/checkEmailExists/${value}`)
            .then((res) => {
              let emailExists = false;
              // the email does not exist.
              if (!res.result) {
                emailExists = false;
              } else {
                if (_this.newMode) {
                  if (res.result && res.result.length > 1) {
                    emailExists = true;
                  }
                } else {
                  if (res.result && res.result !== _this.form.studentId) {
                    emailExists = true;
                  }
                }
              }

              if (emailExists) {
                callback(new Error(`The email ${value} already exists.`));
              } else {
                callback();
              }
            })
            .catch(() => {
              callback(new Error("Unexpected error."));
            });
        } else {
          callback(new Error("The email is empty."));
        }
      }, 100);
    },
    handleStudentSelectionChange(val) {
      this.copyToUserQuery.studentIdList = [];
      if (val && val.length) {
        for (let i = 0; i < val.length; i++) {
          this.copyToUserQuery.studentIdList.push(val[i].studentId);
        }
      }
    },
    copyStudentToUser(index, row) {
      this.$confirm(
        `Are you sure to create a user for the student ${row.studentId}, which will not only copy student's info into user but also send login credentials to registered email？`,
        "Prompt",
        {
          type: "warning",
        }
      )
        .then(() => {
          let query = { all: false, studentIdList: [row.studentId] };
          postData(`${this.controllerUrl}/copySelectedStudentsToUsers`, query)
            .then((res) => {
              if (res.code === "200") {
                this.$alert(
                  res.result.replace(/\r?\n/g, "<br />"),
                  "Feedback",
                  {
                    dangerouslyUseHTMLString: true,
                  }
                );
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    copySelectedStudentsToUsers() {
      if (
        !(
          this.copyToUserQuery.all ||
          this.copyToUserQuery.studentIdList.length > 0
        )
      ) {
        this.$message.warning("No students selected");
        return;
      }

      this.$confirm(
        "Are you sure to make users for the selected students, which will not only copy students' info into users but also send login credentials to registered emails？",
        "Prompt",
        {
          type: "warning",
        }
      )
        .then(() => {
          postData(
            `${this.controllerUrl}/copySelectedStudentsToUsers`,
            this.copyToUserQuery
          )
            .then((res) => {
              if (res.code === "200") {
                this.$alert(
                  res.result.replace(/\r?\n/g, "<br />"),
                  "Feedback",
                  {
                    dangerouslyUseHTMLString: true,
                  }
                );
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    openIssuePointsDialog(index, row) {
      this.currentStudentForIssuePoints = row;
      this.addPointsDialogTitle = "Issue Points";
      this.showAddPointsDialog = true;
    },
    issuedPointsCallback(res) {
      if (res.result && res.code === "200") {
        this.showAddPointsDialog = false;
        this.loadData();
      }
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.dialogHeaderTitle {
  font-size: 18px;
  background-color: #409eff;
  margin-bottom: 20px;
  font-weight: bold;
  color: #000;
  padding: 10px;
}
</style>
